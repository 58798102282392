body {
  background-color: transparent;
  color: #000000;
  font-family: Arial;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.1875;
  margin: 0;
  padding: 0;
}
#wb_Image1 {
  vertical-align: top;
}
#Image1 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_Image2 {
  vertical-align: top;
}
#Image2 {
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#name {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  background-image: url(./images/register-name.png);
  background-repeat: repeat;
  background-position: left top;
  background-size: 100% 100%;
  color: #000000;
  font-family: Arial;
  font-weight: bold;
  font-size: 53px;
  line-height: 100px;
  padding: 4px 4px 4px 4px;
  margin: 0;
  text-align: center;
}
#name:focus {
  border-color: #66afe9;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075),
    0px 0px 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}
#wb_Image3 {
  vertical-align: top;
}
#Image3 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_Image4 {
  vertical-align: top;
}
#Image4 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_Image5 {
  vertical-align: top;
}
#Image5 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_Image7 {
  vertical-align: top;
}
#Image7 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#register_level {
  border: 0px solid #cccccc;
  border-radius: 4px;
  background-color: transparent;
  background-image: url(./images/register-level.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% 100%;
  color: #000000;
  font-family: Arial;
  font-weight: bold;
  font-size: 64px;
  line-height: 100px;
  padding: 4px 4px 4px 4px;
  margin: 0;
  text-align: center;
}
#register_level:focus {
  border-color: #66afe9;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075),
    0px 0px 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}

#Image_Student {
  border: 10px solid #3382a3;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.my_model_pop {
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.CameraFrame {
  /* margin-right: auto;
  margin-left: auto;
  top: 100px;
  position: relative; */
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  /* margin-right: -50%; */
  transform: translate(-50%, -50%);
}
