body {
  background-color: #ffffff;
  color: #000000;
  font-family: Arial;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.1875;
  margin: 0;
  padding: 0;
}
#wb_Image_backgroup {
  vertical-align: top;
}
#Image_backgroup {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_ {
  vertical-align: top;
}

#wb_Into {
  vertical-align: top;
}

# {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-1 {
  vertical-align: top;
}
#state-1 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-2 {
  vertical-align: top;
}
#state-2 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-3 {
  vertical-align: top;
}
#state-3 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-4 {
  vertical-align: top;
}
#state-4 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-5 {
  vertical-align: top;
}
#state-5 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_Image1 {
  vertical-align: top;
}
#Image1 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-pass-1 {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_state-pass-1 div {
  text-align: center;
}
#wb_state-pass-2 {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_state-pass-2 div {
  text-align: center;
}
#wb_state-pass-3 {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_state-pass-3 div {
  text-align: center;
}
#wb_state-pass-4 {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_state-pass-4 div {
  text-align: center;
}
#wb_state-pass-5 {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_state-pass-5 div {
  text-align: center;
}
#wb_state-pass-6 {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_state-pass-6 div {
  text-align: center;
}
#wb_state-lock-1 {
  vertical-align: top;
}
#state-lock-1 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-lock-2 {
  vertical-align: top;
}
#state-lock-2 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-lock-3 {
  vertical-align: top;
}
#state-lock-3 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-lock-4 {
  vertical-align: top;
}
#state-lock-4 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_state-lock-5 {
  vertical-align: top;
}
#state-lock-5 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_Image2 {
  vertical-align: top;
}
#Image2 {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#wb_Image_back {
  vertical-align: top;
}
#Image_back {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.state_number {
  color: '#000000';
  font-family: 'Arial';
  font-size: 27px;
  text-shadow: 0 0 5px #ffffff;
  user-select: 'none';
}
