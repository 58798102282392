body {
  background-color: #ffffff;
  color: #000000;
  font-family: Arial;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.1875;
  margin: 0;
  padding: 0;
}
#wb_lesson_lesson {
  vertical-align: top;
}
#lesson_lesson {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_lesson-back {
  vertical-align: top;
}
#lesson-back {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_lesson_backward {
  vertical-align: top;
}
#lesson_backward {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_lesson_forward {
  vertical-align: top;
}
#lesson_forward {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_lesson_pass {
  vertical-align: top;
}
#lesson_pass {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#wb_finish_score {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_finish_score div {
  text-align: center;
}

#wb_bonus-backgroud {
  vertical-align: top;
}
#bonus-backgroud {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_bonus-circle {
  vertical-align: top;
}
#bonus-circle {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#wb_lesson-bonus {
  background-color: transparent;
  background-image: none;
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  text-align: center;
}
#wb_lesson-bonus div {
  text-align: center;
}
#wb_bonus-star {
  vertical-align: top;
}
#bonus-star {
  border: 0px solid #000000;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
